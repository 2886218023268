import { template as template_5b01d671811d421fb08ee93f626987ad } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_5b01d671811d421fb08ee93f626987ad(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;

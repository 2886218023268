import { template as template_1d630706399347a8a0e967eb710928aa } from "@ember/template-compiler";
const FKText = template_1d630706399347a8a0e967eb710928aa(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
